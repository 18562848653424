export class ContactModel {
  constructor(c = {}) {
    this.id = c.id
    this.name = c.name
    this.logo = c.logo
    this.address = c.address
    this.comment = c.comment
    this.emails = (c.emails || []).map(({ value }) => value)
    this.phones = (c.phones || []).map(({ value }) => value)
    this.mobiles = (c.mobiles || []).map(({ value }) => value)
    this.faxes = (c.faxes || []).map(({ value }) => value)
    this.brand_product_groups = (c.brand_product_groups || []).map(
      (b) => b.value,
    )
    this.isDistributionPartner = !!c.isDistributionPartner
    this.isFallback = !!c.isFallback
    this.countries = c.countries || []
  }

  matchesCountry(country) {
    if (!country || !country.name.key) {
      return true
    }
    return (
      this.countries.findIndex((c) => c.name.key === country.name.key) !== -1
    )
  }

  matchesBrandProductGroup(brandProductGroup, t) {
    if (!brandProductGroup || !brandProductGroup.key) {
      return true
    }
    return (
      this.brand_product_groups.findIndex(
        ({ key }) => t(key) === t(brandProductGroup.key),
      ) !== -1
    )
  }
}
