import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import uid from "uid"

import "./select.sass"

class Select extends React.Component {
  constructor(props) {
    super(props)

    const mapToString = props.mapValueToString || ((val) => val)
    this.mapValueToString = (val) => {
      if (val) {
        return mapToString(val)
      }
      return this.props.defaultText
    }
    this.mapValueForValueAsString =
      props.mapValueForValueAsString || ((val) => this.mapValueToString(val))
    this.mapValueToKey =
      props.mapValueToKey || ((val) => this.mapValueToString(val))

    let value = this.props.value
      ? this.props.value
      : this.props.values.find(
          (v) => this.mapValueForValueAsString(v) === this.props.valueAsString,
        )

    if (!value && !this.props.allowUndefined) {
      value = this.props.values[0]
    }

    this.state = {
      id: uid(),
      value: value ? this.mapValueToKey(value) : undefined,
    }

    if (this.props.onSelect) {
      if (!this.props.value && value) {
        this.props.onSelect(value)
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.mapValueToKey(this.props.value) })
    }
    if (prevProps.valueAsString !== this.props.valueAsString) {
      const value = this.props.values.find(
        (v) => this.mapValueForValueAsString(v) === this.props.valueAsString,
      )
      this.setState({ value: this.mapValueToKey(value) })
    }
  }

  select(stringValue) {
    const value = this.props.values.find(
      (v) => this.mapValueToKey(v) === stringValue,
    )

    this.setState({ value: this.mapValueToKey(value) })
    if (this.props.onSelect) {
      this.props.onSelect(value)
    }
  }

  render() {
    const {
      groups,
      values,
      value,
      valueAsString,
      darkAppearance,
      defaultText,
      onSelect,
      required,
      mapValueToString,
      mapValueForValueAsString,
      mapValueToKey,
      titleTranslatedString,
      name,
      allowUndefined,
      ...props
    } = this.props
    const { id } = this.state

    return (
      <div className={"_fp-select"}>
        <select
          {...props}
          required={required}
          name={name || titleTranslatedString}
          className={classNames("_fp-select__select", {
            "_fp-select__select--dark": darkAppearance,
          })}
          value={this.state.value}
          onChange={(ev) => {
            this.select(ev.target.value)
          }}
          onBlur={(ev) => {
            this.select(ev.target.value)
          }}
        >
          {allowUndefined && <option value={""}>{defaultText}</option>}
          {groups ? (
            <>
              {groups.map((group, groupIndex) => {
                return (
                  <optgroup
                    label={group.label}
                    key={`select_${id}_optgroup_${groupIndex}`}
                  >
                    {values.slice(group.from, group.to).map((value, index) => {
                      return (
                        <option
                          key={`select_${id}_option_${index + group.from}`}
                          value={this.mapValueToKey(value)}
                        >
                          {this.mapValueToString(value)}
                        </option>
                      )
                    })}
                  </optgroup>
                )
              })}
            </>
          ) : (
            <>
              {values.map((value, index) => {
                return (
                  <option
                    key={`select_${id}_option_${index}`}
                    value={this.mapValueToKey(value)}
                  >
                    {this.mapValueToString(value)}
                  </option>
                )
              })}
            </>
          )}
        </select>
      </div>
    )
  }
}

Select.propTypes = {
  defaultText: PropTypes.string,
  darkAppearance: PropTypes.bool,
  allowUndefined: PropTypes.bool,
  required: PropTypes.bool,
  values: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  mapValueToString: PropTypes.func,
  mapValueToKey: PropTypes.func,
  value: PropTypes.any,
  titleTranslatedString: PropTypes.string,
}

Select.defaultProps = {
  defaultText: "-",
  allowUndefined: true,
}

export default Select
