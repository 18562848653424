import { flatten, uniqBy, uniqWith } from "lodash"
import PropTypes from "prop-types"
import React from "react"

import { makePromiseCancelable } from "../../util/make-promise-cancelable"
import trackCustomEvent from "../../util/trackCustomEvent"
import Button from "../button/button"
import OverviewGrid from "../overview-grid/overview-grid"
import ContactSelect from "./contact-select"
import ContactTile from "./contact-tile"
import "./contact-view.sass"

class ContactView extends React.Component {
  constructor(props) {
    super(props)

    this.countryGroups = []
    this.countryValues = []

    const groups = {}

    for (const contact of props.contacts) {
      for (const country of contact.countries) {
        if (!groups[country.region]) {
          groups[country.region] = []
        }
        groups[country.region].push(country)
      }
    }

    this.fallbackContact = props.contacts.find((c) => c.isFallback)

    const { t } = props

    for (const groupsKey in groups) {
      if (groups.hasOwnProperty(groupsKey)) {
        groups[groupsKey] = uniqBy(
          groups[groupsKey],
          (country) => country.name.key,
        ).sort((a, b) => {
          return t(a.name.key).localeCompare(t(b.name.key))
        })

        const start = this.countryValues.length
        this.countryValues.push(...groups[groupsKey])
        this.countryGroups.push({
          label: t(`CONTACT-COUNTRY_GROUP-${groupsKey.toUpperCase()}`),
          from: start,
          to: this.countryValues.length,
        })
      }
    }

    this.brandProductGroups = uniqBy(
      flatten(props.contacts.map((c) => c.brand_product_groups)),
    )

    this.state = {
      country: undefined,
      brandProductGroup: undefined,
      showContacts: false,
      filteredContacts: [],
    }
  }

  componentDidMount() {
    this.countryPromise = makePromiseCancelable(
      fetch("https://api.ipcountry.foolsparadise.de/ip")
        .then((res) => res.json())
        .then((res) => (res.country || "de").toUpperCase()),
    )

    this.countryPromise.then((res) => {
      this.setCountry(
        this.countryValues.find((country) => country.code === res),
      )
    })
  }

  componentWillUnmount() {
    this.countryPromise.cancel()
  }

  setCountry(val) {
    this.updateFilters(val, this.state.brandProductGroup)

    this.setState({
      country: val,
    })
  }

  setBrandProductGroup(brandProductGroup) {
    this.updateFilters(this.state.country, brandProductGroup)

    this.setState({
      brandProductGroup,
    })
  }

  updateFilters(country, brandProductGroup) {
    let filteredRes = this.props.contacts.filter((c) => {
      return (
        c.matchesCountry(country) &&
        c.matchesBrandProductGroup(brandProductGroup, this.props.t)
      )
    })

    if (filteredRes.findIndex((c) => c.id === this.fallbackContact.id) === -1) {
      const test = filteredRes.filter((c) => {
        for (const brandProductGroup1 of this.brandProductGroups) {
          if (!c.matchesBrandProductGroup(brandProductGroup1, this.props.t)) {
            return false
          }
        }
        return true
      })

      // show fallback contact if no munzing contact is shown
      if (test.findIndex((c) => !c.isDistributionPartner) === -1) {
        filteredRes = [this.fallbackContact, ...filteredRes]
      }
    }

    this.setState({
      filteredContacts: filteredRes,
    })
  }

  render() {
    const { t } = this.props
    return !(
      this.state.country &&
      this.state.brandProductGroup &&
      this.state.showContacts
    ) ? (
      <div
        className={"_fp-global-container-tertiary _fp-country-view-container"}
      >
        <div className="_fp-grid _fp-grid--gap">
          <div className="_fp-col-12">
            <h2 className={"_fp-heading-2"}>{t("MENU-FIND_A_CONTACT")}</h2>
          </div>
          <div className="_fp-col-12">
            <ContactSelect
              countryValues={this.countryValues}
              countryGroups={this.countryGroups}
              brandProductGroups={this.brandProductGroups}
              country={this.state.country}
              brandProductGroup={this.state.brandProductGroup}
              setCountry={(country) => this.setCountry(country)}
              setBrandProductGroup={(g) => this.setBrandProductGroup(g)}
              allowUndefined={true}
            />
          </div>
          <div className="_fp-col-12">
            <Button
              buttonLabel={t("CONTACT-SHOW_CONTACTS")}
              buttonCallback={() => {
                this.setState({ showContacts: true })
                trackCustomEvent({
                  category: "contact",
                  action: "show_contacts_clicked",
                })
              }}
              buttonStyle={
                this.state.country && this.state.brandProductGroup
                  ? "green"
                  : "disabled"
              }
              buttonSize={"large"}
            />
          </div>
        </div>
      </div>
    ) : (
      <OverviewGrid
        topRowChildren={
          <ContactSelect
            countryValues={this.countryValues}
            countryGroups={this.countryGroups}
            brandProductGroups={this.brandProductGroups}
            country={this.state.country}
            brandProductGroup={this.state.brandProductGroup}
            setCountry={(country) => this.setCountry(country)}
            setBrandProductGroup={(g) => this.setBrandProductGroup(g)}
          />
        }
      >
        {uniqWith(
          this.state.filteredContacts,
          (a, b) =>
            a.name === b.name &&
            a.address === b.address &&
            a.phone === b.phone &&
            a.email === b.email,
        )
          .sort((c1, c2) => {
            if (c1.isDistributionPartner && !c2.isDistributionPartner) {
              return 1
            }
            if (!c1.isDistributionPartner && c2.isDistributionPartner) {
              return -1
            }
            return c1.name.localeCompare(c2.name)
          })
          .map((contact) => (
            <ContactTile contact={contact} key={`contact_${contact.id}`} />
          ))}
      </OverviewGrid>
    )
  }
}

ContactView.propTypes = {
  contacts: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
}

export default ContactView
