import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import "./form-field.sass"

const FormField = ({
  titleTranslatedString,
  children,
  fullWidth,
  disabled,
}) => {
  return (
    <div
      className={classNames(
        "_fp-form-field",
        { "_fp-form-field--full-width": fullWidth },
        { "_fp-form-field--disabled": disabled },
      )}
    >
      {titleTranslatedString && (
        <label
          className={"_fp-form-field__label"}
          htmlFor={titleTranslatedString}
        >
          <p className={"_fp-text _fp-text--small-dark"}>
            {titleTranslatedString}
          </p>
        </label>
      )}
      {children}
    </div>
  )
}

FormField.propTypes = {
  titleTranslatedString: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default FormField
