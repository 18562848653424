import { graphql, useStaticQuery } from "gatsby"

import { ContactModel } from "../model/contactModel"

const useContacts = () => {
  return useStaticQuery(graphql`
    query ContactsQuery {
      allStrapiContact {
        edges {
          node {
            ...ContactParams
          }
        }
      }
    }
  `).allStrapiContact.edges.map(({ node }) => new ContactModel(node))
}

export default useContacts
