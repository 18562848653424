import React from "react"

import ContactView from "../components/contact/contact-view"
import PageHeader from "../components/page-header/page-header"
import SEO from "../components/seo"
import useBrands from "../hooks/useBrands"
import useContacts from "../hooks/useContacts"
import { useTranslation } from "../i18n/translate"

const ContactsPage = ({ location }) => {
  const contacts = useContacts()
  const brands = useBrands()
  const { t } = useTranslation()

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("CONTACT-META_TITLE")}
        descriptionTranslatedString={t("CONTACT-META_DESCRIPTION")}
      />
      <PageHeader
        levelTwoTitle={t("CONTACT-META_TITLE")}
        levelThreeTitle={t("CONTACT-META_TITLE")}
      />

      <div className="_fp-global-container">
        <div className="_fp-col-12">
          <ContactView contacts={contacts} brands={brands} t={t} />
        </div>
      </div>
    </>
  )
}

export default ContactsPage
