import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../i18n/translate"
import useIsClient from "../../util/is-client"
import Badge from "../badge/badge"
import Tile from "../tile/tile"
import "./contact-tile.sass"

const ContactTile = ({ contact }) => {
  const { t } = useTranslation()

  const isClient = useIsClient()

  return (
    <div className={"_fp-contact-tile"}>
      <Tile tileSize={"s"}>
        <Badge
          showBadge={contact.isDistributionPartner}
          textTranslatedString={t("CONTACT-DISTRIBUTION_PARTNER")}
        />
        <div className="_fp-contact-tile__contact-info">
          <div className="_fp-contact-tile__contact-info__logo">
            {contact.logo && (
              <GatsbyImage
                sizes={
                  "(max-width: 600px) 100vw, (min-width: 601px) and (max-width: 900px) 50vw, (min-width: 901px) and (max-width: 1600px) 33vw, 22rem"
                }
                className={"_fp-contact-tile__contact-info__logo__image"}
                image={contact.logo?.localFile.childImageSharp?.gatsbyImageData}
                alt={contact.name}
              />
            )}
          </div>
          <p className="_fp-contact-tile__contact-info__name _fp-heading-5">
            {contact.name}
          </p>
          <div className="_fp-text _fp-contact-tile__contact-info__main-content">
            <p className="_fp-text _fp-contact-tile__contact-info__main-content__address">
              {contact.address}
            </p>
            {contact.comment && (
              <p className="_fp-text _fp-contact-tile__contact-info__main-content__comment">
                <i>{contact.comment}</i>
              </p>
            )}
          </div>

          {isClient && (
            <div className={"_fp-contact-tile__contact-info__contact"}>
              {contact.phones.length > 0 && (
                <>
                  <p>{t("GLOBAL-PHONE_COLON")}</p>
                  <div className="_fp-contact-tile__contact-info__contact__phone">
                    {contact.phones.map((phone, index, array) => (
                      <p key={`phone_${index}`}>
                        <a className={"_fp-text-link"} href={`phone:${phone}`}>
                          {phone}
                        </a>
                        {index !== array.length - 1 ? ", " : ""}
                      </p>
                    ))}
                  </div>
                </>
              )}
              {contact.mobiles.length > 0 && (
                <>
                  <p>{t("GLOBAL-MOBILE_COLON")}</p>
                  <div className="_fp-contact-tile__contact-info__contact__mobile">
                    {contact.mobiles.map((mobile, index, array) => (
                      <p key={`mobile_${index}`}>
                        <a className={"_fp-text-link"} href={`phone:${mobile}`}>
                          {mobile}
                        </a>
                        {index !== array.length - 1 ? ", " : ""}
                      </p>
                    ))}
                  </div>
                </>
              )}
              {contact.faxes.length > 0 && (
                <>
                  <p>{t("GLOBAL-FAX_COLON")}</p>
                  <div className="_fp-contact-tile__contact-info__contact__fax">
                    {contact.faxes.map((fax, index, array) => (
                      <p key={`fax_${index}`}>
                        <a className={"_fp-text-link"} href={`fax:${fax}`}>
                          {fax}
                        </a>
                        {index !== array.length - 1 ? ", " : ""}
                      </p>
                    ))}
                  </div>
                </>
              )}
              {contact.emails.length > 0 && (
                <>
                  <p>{t("GLOBAL-EMAIL_COLON")}</p>
                  <div className="_fp-contact-tile__contact-info__contact__email">
                    {contact.emails.map((email, index, array) => (
                      <p key={`email_${index}`}>
                        <a className={"_fp-text-link"} href={`mailto:${email}`}>
                          {email}
                        </a>
                        {index !== array.length - 1 ? ", " : ""}
                      </p>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </Tile>
    </div>
  )
}

ContactTile.propTypes = {
  contact: PropTypes.object.isRequired,
}

export default ContactTile
