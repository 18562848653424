import { flatten, sortBy, uniqBy } from "lodash"
import PropTypes from "prop-types"
import React from "react"

import { withTranslation } from "../../i18n/translate"
import { makePromiseCancelable } from "../../util/make-promise-cancelable"
import FormField from "../form/form-field/form-field"
import Select from "../form/select/select"

class ContactSelect extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { t } = this.props
    const { country, brandProductGroup } = this.props

    const translationStringToString = (val) =>
      val && val.key ? t(val.key) : ""

    const brandProductGroups = sortBy(
      uniqBy(this.props.brandProductGroups, translationStringToString),
      translationStringToString,
    )

    return (
      <>
        <FormField titleTranslatedString={t("GLOBAL-REGION")}>
          <Select
            allowUndefined={!!this.props.allowUndefined}
            values={this.props.countryValues}
            groups={this.props.countryGroups.sort((a, b) =>
              a.label.localeCompare(b.label),
            )}
            mapValueToString={(val) =>
              val && val.name && val.name.key ? t(val.name.key) : ""
            }
            onSelect={(val) => this.props.setCountry(val)}
            value={country}
          />
        </FormField>

        <FormField titleTranslatedString={t("CONTACT-BRAND_PRODUCT_GROUP")}>
          <Select
            allowUndefined={!!this.props.allowUndefined}
            values={brandProductGroups}
            mapValueToString={translationStringToString}
            onSelect={(val) => this.props.setBrandProductGroup(val)}
            value={brandProductGroup}
          />
        </FormField>
      </>
    )
  }
}

ContactSelect.propTypes = {
  countryValues: PropTypes.array.isRequired,
  countryGroups: PropTypes.array.isRequired,
  brandProductGroups: PropTypes.array.isRequired,

  country: PropTypes.object,
  brandProductGroup: PropTypes.object,

  setCountry: PropTypes.func.isRequired,
  setBrandProductGroup: PropTypes.func.isRequired,

  allowUndefined: PropTypes.bool,
}

export default withTranslation()(ContactSelect)
